import { useState, useEffect, useMemo } from 'react'
import './FacetedSearchSelect.scss'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon.js'
import Input from '../Input/Input.js'

const Option = ({ label, onFacetedSearchSelect, selected, customLabel, focussed, setFocussed }) => {
  return (
    <div className={`FacetedSearchSelect-option ${focussed ? 'FacetedSearchSelect-option-focussed' : ''}`} onClick={onFacetedSearchSelect} onMouseEnter={() => setFocussed()}>
      {customLabel || <p>{label.label} ({label.count})</p>}
      {selected && <Icon className='FacetedSearchSelect-option-check' name='check' fill='var(--green)' />}
    </div>
  )
}

const FacetedSearchSelect = ({ label, value, onChange, options = [], placeholder = 'Kies een optie', className = '', id, valid, disabled = false }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [focussed, setFocussed] = useState(options.findIndex(option => option.label === value))
  const [inputValue, setInputValue] = useState(value)
  const [count, setCount] = useState(options.find(option => option.label === value)?.count)
  id = useMemo(() => { return id || 'FacetedSearchSelect-' + (Math.round(Math.random() * 10000000)).toString() }, [id])
  const matchMulti = (string, words) => {
    let count = 0
    for (const word of words) {
      if (string.trim().toLowerCase().includes(word.trim().toLowerCase() || '')) {
        count++
      }
    }
    return (count > (words.length - 1))
  }
  const filteredOptions = options.filter(option => matchMulti(option.label || '', inputValue.split(' ')))

  useEffect(() => {
    setInputValue(value)
    setCount(options.find(option => option.label === value)?.count)
  }, [value, options])

  useEffect(() => {
    function handleWindowClick (e) {
      if (!e.target.closest('#' + id)) {
        setIsOpen(false)
      }
    }

    if (isOpen) {
      window.addEventListener('click', handleWindowClick)
    }

    return () => {
      window.removeEventListener('click', handleWindowClick)
    }
  }, [isOpen, id])

  useEffect(() => {
    function handleKeyPress (e) {
      switch (e.code) {
        case 'ArrowDown': {
          setFocussed((focussed + 1 + filteredOptions.length) % filteredOptions.length)
          break
        }
        case 'ArrowUp': {
          setFocussed((focussed - 1 + filteredOptions.length) % filteredOptions.length)
          break
        }
        case 'Escape': {
          setIsOpen(false)
          break
        }
        case 'Enter': {
          onChange(filteredOptions[focussed].label)
          setIsOpen(false)
          break
        }
        default:
      }
    }

    if (isOpen) {
      window.addEventListener('keydown', handleKeyPress)
    }

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [isOpen, focussed, onChange, filteredOptions])

  function handleOptionClick (option) {
    return () => {
      onChange(option.label)
      setCount(option.count)
      setIsOpen(false)
    }
  }

  function handleChange (e) {
    setInputValue(e.target.value)
    setCount(options.find(option => option.label === e.target.value)?.count)
    onChange(e.target.value)
  }

  const showValue = count ? `${inputValue} (${count})` : inputValue

  return (
    <div className={`FacetedSearchSelect ${className}`} id={id}>
      <Input placeholder={placeholder} label={label} value={showValue} onChange={handleChange} onClick={() => setIsOpen(true)} valid={valid || new Set(options).has(inputValue)} disabled={disabled} />
      {isOpen &&
        <div className='FacetedSearchSelect-options'>
          {filteredOptions.map((option, i) => <Option key={`${label}_${i}`} label={option} onFacetedSearchSelect={handleOptionClick(option)} selected={value === option} focussed={focussed === i} setFocussed={() => setFocussed(i)} />)}
        </div>}
    </div>
  )
}

FacetedSearchSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  id: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string
}

export default FacetedSearchSelect
